/* ==========================================================================
  Main.js
========================================================================== */

(function($) {

  // Aucor navigation
  $(document).ready(function(){
    $('.main-navigation').aucor_navigation({
      desktop_min_width: 920, // min width in pixels
      menu_toggle: "#menu-toggle" // selector for toggle
    });
  });

  // Responsive videos
  $(document).ready(function(){
    $('body').fitVids();
  });

  $(".open-contact").click(function(){
	$("#contact").toggleClass('open');
  });

  $('.open-contact').click(function(){
	$(this).toggleClass('active');
  });

  if ($('.sf-input-select').length ) {
    $('.sf-input-select').chosen({
      search_contains: true
    });
  }

  // sticky header
  var header = document.getElementById('masthead');
  var headroom  = new Headroom(header, {
    offset: 128,
    tolerance: {
      up: 10,
      down: 0
    }
  });
  headroom.init();

  // search toggle
  var search_toggle = function() {
    var search_toggle = document.getElementById('search-toggle');
    var search_toggle_mobile = document.getElementById('search-toggle-mobile');
    var search_field = document.getElementById('header-search-wrapper');

    search_toggle.addEventListener('click', function(){
      if (search_toggle.classList.contains('active')) {
        // remove .active class from search icon
        search_toggle.classList.remove('active');
        search_toggle.setAttribute('aria-expanded', 'false');

        // remove .active class from search container
        search_field.classList.remove('active');
        document.getElementById('masthead').classList.remove('search-open');

        // focus out of the menu
        search_toggle.dispatchEvent(new Event('focus'));

      } else {
        // .active class to search icon
        search_toggle.classList.add('active');
        search_toggle.setAttribute('aria-expanded', 'true');

        // .active class to search container
        search_field.classList.add('active');
        document.getElementById('masthead').classList.add('search-open');

        // set focus to input field
        document.getElementById('header-search-input').focus();
      }
    });

    search_toggle_mobile.addEventListener('click', function(){
      if (search_toggle.classList.contains('active')) {
        // remove .active class from search icon
        search_toggle.classList.remove('active');
        search_toggle.setAttribute('aria-expanded', 'false');

        // remove .active class from search container
        search_field.classList.remove('active');
        document.getElementById('masthead').classList.remove('search-open');

        // focus out of the menu
        search_toggle.dispatchEvent(new Event('focus'));

      } else {
        // .active class to search icon
        search_toggle.classList.add('active');
        search_toggle.setAttribute('aria-expanded', 'true');

        // .active class to search container
        search_field.classList.add('active');
        document.getElementById('masthead').classList.add('search-open');

        // set focus to input field
        document.getElementById('header-search-input').focus();
      }
    });
  }
  search_toggle();

})(jQuery);
